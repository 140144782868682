import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Form from '~components/Form'
import Icon from '~components/Icon'
import S from '~components/seo'
import { Link } from 'gatsby'
import useAuth from '~hooks/useAuth'
import DATA from './constants'

import * as s from './Login.module.scss'

const Login = () => {
  const auth = useAuth()
  const isAuthenticated = !!auth.token
  const { t } = useTranslation()
  return (
    <section className={s.login_wrapper}>
      <S title={t(`titles.login`)} />

      <div className={s.login_form}>
        <Link to="/" className={s.login_icon}>
          <Icon name="logo_header" size={(141, 77)} />
        </Link>
        <Badge bg="primary">{t(`login.form.title`)}</Badge>
        <Form isAuth={isAuthenticated} {...auth} data={DATA} variant="login" />
        <Button href="/registration" variant="outline-success">
          {t(`login.btn`)}
        </Button>
      </div>
      <div className={s.login_ill}>
        <StaticImage
          src="./img/log-min.png"
          alt="login"
          placeholder="tracedSVG"
        />

        <h1>{t(`login.title`)}</h1>
        <p>{t(`login.descr`)}</p>
      </div>
    </section>
  )
}

export default Login
