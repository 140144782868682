import { graphql } from 'gatsby'
import React from 'react'
import Login from '~containers/Login'

const LoginPage = () => <Login />

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default LoginPage
